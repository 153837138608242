<template>
  <section class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="search-wrap main-section">
      <div class="title-tag">查询内容</div>
      <div class="query-content">{{ keywordTitle }}</div>
      <div class="title-tag">摘要</div>
      <div class="base-info">
        <div v-if="dataList.length == 0" class="empty-res">
          {{ keyword ? "暂无该查询内容匹配的相关信息。" : "" }}
        </div>
        <template v-else>
          <div v-for="(item, i) in dataList" :key="i" class="base-row">
            <div class="title">{{ item.name }}</div>
            <div class="content">{{ item.value }}</div>
          </div>
        </template>
      </div>
      <div @click="back" class="ls-btns-block">返回</div>
    </div>
  </section>
</template>
<script>
import BreadCrump from "@/components/common/BreadCrump.vue";
import { blockH, tradingHash } from "@/api/interface/outsideQuery.js";
import { mapGetters, mapMutations } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    BreadCrump,
  },
  data() {
    return {
      breadcrumbList: [
        { menuName: "综合查询", routeName: "IntegratedQuery" },
        { menuName: "查询结果", curCss: "cur" },
      ],
      dataList: [],
      rules: /^[0-9]*$/,
    };
  },
  computed: {
    ...mapGetters(["queryInfo"]),
    keyword() {
      let integratedKeyword = this.queryInfo.integratedKeyword;
      if (integratedKeyword) {
        return integratedKeyword;
      }
      const queryInfo = sessionStorage.getItem("queryInfo") || "{}";
      integratedKeyword = JSON.parse(queryInfo).integratedKeyword;
      if (integratedKeyword) {
        this.setQueryInfo({
          integratedKeyword,
        });
        return integratedKeyword;
      }
      return "";
    },
    keywordTitle() {
      if (!this.keyword) {
        return "暂无查询条件";
      }
      return (
        (this.rules.test(this.keyword) ? "块 - " : "交易 - ") + this.keyword
      );
    },
  },
  methods: {
    ...mapMutations(["setQueryInfo"]),
    back() {
      history.back();
    },
    getDataList() {
      const keyword = this.keyword;
      if (this.rules.test(keyword)) {
        blockH(keyword).then((data) => {
          data
            ? (this.dataList = [
                { name: "区块高度", value: data.number },
                {
                  name: "区块哈希",
                  value: data.hash,
                },
                { name: "交易数量", value: data.txs },
                { name: "前一个区块哈希", value: data.parentHash },
                {
                  name: "出块时间",
                  value: dayjs(data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                },
                { name: "记账节点", value: data.nodeName },
              ])
            : (this.dataList = []);
        });
      } else {
        tradingHash(keyword).then((data = {}) => {
          data
            ? (this.dataList = [
                {
                  name: "交易哈希",
                  value: data.hash,
                },
                {
                  name: "发送方",
                  value: data.fromAddress,
                },
                {
                  name: "接收方",
                  value: data.toAddress,
                },
                {
                  name: "交易发起时间",
                  value: dayjs(data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                },
                { name: "区块高度", value: data.blockNumber },
                {
                  name: "出块时间",
                  value: dayjs(data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                },
                { name: "记账节点", value: data.nodeName },
              ])
            : (this.dataList = []);
        });
      }
    },
  },
  created() {
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
.search-wrap {
  position: relative;
  padding: 36px 51px 0;
  min-height: 705px;
  background-color: #fff;
  margin-top: 16px;
  min-height: 705px;
  .title-tag {
    width: auto;
    font-size: 18px;
    padding: 12px 0;
    background-color: #1d8db9;
    border-radius: 12px;
    width: 180px;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }
  .query-content {
    font-size: 14px;
    color: #3d3d3d;
    padding-left: 30px;
    margin-bottom: 30px;
  }
  .empty-res {
    font-size: 18px;
    font-weight: bold;
    padding-left: 30px;
    margin-bottom: 30px;
  }
  .base-info {
    display: inline-block;
    .base-row {
      color: #3d3d3d;
      border-bottom: 1px solid #dddddd;
      padding: 20px 30px;
      width: auto;
      .title,
      .content {
        display: inline-block;
        vertical-align: middle;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        width: 160px;
      }
      .content {
        font-size: 14px;
        word-break: break-all;
        max-width: 1200px;
      }
    }
  }
  .ls-btns-block {
    position: relative;
    margin-top: 60px;
  }
}
</style>
